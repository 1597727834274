@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Mulish/Mulish-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Roboto italic */
@font-face {
  font-family: "Roboto";

  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
/* Roboto bold */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Cormorant Garamond" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.chartjs-render-monitor {
  display: inline-table;
}

/* ::-webkit-scrollbar {
  display: none;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
span {
  font-family: Roboto;
}
.myCustomCalendar {
  width: 29em !important; /* previously was 33em */
}
.myCustomCalendar .Calendar__section.-hiddenNext {
  transform: translateX(100%); /* previously was 90% */
}

.myCustomCalendar .Calendar__section.-hiddenPrevious {
  transform: translateX(-100%); /* previously was -90% */
}

.myCustomCalendar .Calendar__section {
  padding: 0 1em; /* previously was 0 3.2em */
}

.myCustomCalendar .Calendar__weekDays {
  padding: 0 0.8em; /* previously was 0 2.6em */
}
.myCustomCalendar .Calendar__monthText {
  padding: 0 0.8em; /* previously was 0 2.6em */
  /* border: 1px solid red; */

  /* box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px; */
  color: black;
  background-color: white !important;
}

.myCustomCalendar .Calendar__monthText:hover {
  /* previously was 0 2.6em */
  /* border: 1px solid red; */

  /* box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px; */
  color: #000;
}
.myCustomCalendar .Calendar__monthYear {
  padding: 0 0.8em; /* previously was 0 2.6em */
  /* border: 1px solid red; */

  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  background: #306fbc;
  color: black;
  height: 28px;
}
.myCustomCalendar .Calendar__monthYear > * {
  background-color: white !important;
  height: 20px;
}
.myCustomCalendar .Calendar__monthYear > *:hover {
  color: #000;
}
.my-custom-input-class:hover {
  background-color: #f7f7f7;
}

.my-custom-input-class:hover .MuiSvgIcon-root{
  color: #FFFFFF;
}

.date-button-container .MuiSvgIcon-root{
  color: #306fbc;
}

.date-button-container .MuiButtonBase-root, 
.disabled-date-button-container .MuiButtonBase-root{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 4px;
}

.DatePicker {
  z-index: 98 !important;
}
.ql-container {
  flex: 1; 
  display: flex;
  flex-direction: column;
}

.ql-editor {
  flex: 1; 
  overflow-y: auto; 
}
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;;
    content: "Roboto" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before{
  font-family: "Arial";;
  content: "Arial" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before{
  font-family: "Georgia";;
  content: "Georgia" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
{
    font-family: "Montserrat";;
    content: "Montserrat" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before
{
    font-family: "Tahoma";;
    content: "Tahoma" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
    font-family: "Lato", cursive;;
    content: "Lato" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Impact"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Impact"]::before
{
    font-family: "Impact";
    content: "Impact" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
{
    font-family: "Rubik";
    content: "Rubik" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Arial {
  font-family: "Arial";
}
.ql-font-Georgia {
  font-family: "Georgia";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Tahoma {
  font-family: "Tahoma";
}.ql-font-Impact {
  font-family: "Impact";
}
.ql-font-Rubik {
  font-family: "Rubik";
}
